import React from "react";
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import BookingModule from '../components/booking-module'
import { useReservationsData } from "../hooks/use-reservations-data"

class BookingPage extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title;


    const ReservationComponent = () => {
      const { backgroundImage } = useReservationsData();
      return (
        <div className="reservation-hero-module" style={{backgroundImage: "url(" + backgroundImage.childImageSharp.fixed.src + ")"}}>
          <BookingModule />
        </div>
      )
    }

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Reservations - Niwot Inn &amp; Spa" />
        <div className="reservations-page">
          <ReservationComponent />
        </div>
      </Layout>
    );
  };
}

export default BookingPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
