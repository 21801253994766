import { useStaticQuery, graphql } from "gatsby"
export const useReservationsData = () => {
  const data = useStaticQuery(
    graphql`
      {
        pagesReservationsYaml {
          backgroundImage {
            childImageSharp {
              fixed(height: 600, toFormat: JPG, quality: 100) {
                src
              }
            }
          }
        }
      }
    `
  )
  return data.pagesReservationsYaml
}